.about {
  padding: 110px 70px;
  background-color: #202020;
}

.about__items {
  display: flex;
  margin: 0;
  margin-top: 70px;
  padding: 0;
  list-style: none;
}

.about__item {
  flex: 1;
}

.about__item:not(:last-child) {
  margin-right: 40px;
}

.about__item-title {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.8px;
}

.about__item-text {
  margin: 0;
  margin-top: 26px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.about__graph {
  margin-top: 110px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: minmax(140px, 1fr) 4fr;
}

.about__graph-stripe {
  padding: 9px 0 9px;
  margin: 0;
  text-align: center;

  color: #fff;
  background-color: #303030;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

}

.about__graph-stripe_highlighted {
  color: #000;
  background-color: #3ddc84;
}

.about__graph-legend {
  padding-top: 14px;
  margin: 0;
  text-align: center;
  color: #8b8b8b;

  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}


@media screen and (max-width: 768px) {
  .about {
    padding: 90px 50px;
  }
  
  .about__item:not(:last-child) {
    margin-right: 30px;
  }
  
  .about__item-text {
    margin-top: 22px;
    font-size: 12px;
    line-height: 18px;
  }
  
  .about__graph {
    margin-top: 93px;
  }
  
  .about__graph-legend {
    color: #a0a0a0;
    font-weight: 400;
  }
}


@media screen and (max-width: 480px) {
  .about {
    padding: 70px 18px;
  }
  
  .about__items {
    flex-direction: column;
    margin-top: 60px;
  }
  
  .about__item:not(:last-child) {
    margin: 0;
    margin-bottom: 56px;
  }
  
  .about__item-title {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.72px;
  }
  
  .about__item-text {
    margin-top: 16px;
    font-size: 11px;
    line-height: 16px;
  }
  
  .about__graph {
    margin-top: 60px;
    grid-template-columns: minmax(100px, 1fr) 4fr;
  }
  
  .about__graph-stripe {
    padding: 11px 0 11px;
  
    font-size: 11px;
    line-height: 13px;
  }
  
  .about__graph-legend {
    padding-top: 10px;
    
    font-size: 11px;
    line-height: 13px;
  }
}