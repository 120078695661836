.main-promo {
  flex-grow: 1;
  background-color: #202020;
}

.hero {
  height: 100vh;
  position: relative;
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.26);
}

.hero__image {
  display: block;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero__content-container {
  position: absolute;
  top: calc(50% + 11px);
  left: 0;
  width: 100%;
}

.hero__header-container {
  padding: 29px 70px 21px;
  background-color: rgba(0, 0, 0, 0.6);

}

.hero__header {
  padding: 0;
  margin: 0;
  color: #fff;
  font-size: 48px;
  font-weight: 400;
  line-height: 59px;
}

.hero__text {
  padding: 0;
  margin: 0;
  margin-top: 29px;

  color: #fff;
  font-size: 32px;
  font-weight: 300;
  line-height: 39px;
  text-align: right;
}

.space::before {
  content: '\00a0';
}

.hero__button {
  display: block;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 11px;
  padding: 15px 42px;
  background-color: #209cfc;
  border: none;
  border-radius: 6px;


  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.hero__button:hover {
  opacity: 0.96;
}

.hero__button:active {
  transform: scale(0.96);
}


@media screen and (max-width: 880px) {
  .hero__content-container {
    top: 50%;
  }

  .hero__header-container {
    padding: 22px 30px 28px;
  }
  
  .hero__header {
    text-align: center;
  }
  
  .hero__text {
    margin-top: 26px;
  
    font-size: 27px;
    font-weight: 300;
    line-height: 33px;
    text-align: center;
  }

  .hero__button {
    margin-right: 30px;
    margin-top: 16px;
  }
}


@media screen and (max-width: 767px) {
  .hero__header-container {
    padding: 28px 30px 27px;
    background-color: rgba(0, 0, 0, 0.65);
  }

  .hero__header {
    font-size: 38px;
    font-weight: 400;
    line-height: 46px;
  }
  
  .hero__text {
    margin-top: 22px;
  
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
  }
}


@media screen and (max-width: 639px) {
  .hero__header-container {
    padding: 28px 20px 27px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .hero__header {
    font-size: 30px;
    font-weight: 400;
    line-height: 37px;
  }
  
  .hero__text {
    font-size: 22px;
    font-weight: 300;
    line-height: 27px;
  }

  .hero__button {
    margin-right: 20px;
  }
}


@media screen and (max-width: 480px) {
  .space::before {
    content: " ";
  }

  .hero::before {
    background-color: rgba(0, 0, 0, 0.55);
  }

  .hero__content-container {
    top: calc(50% + 30px);
    transform: translateY(-50%);
  }
  
  .hero__header-container {
    padding: 33px 14px 11px;
    background-color: rgba(0, 0, 0, 0.60);
  
  }
  
  .hero__header {
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    text-align: right;
  }
  
  .hero__text {
    margin-top: 33px;
  
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: right;
  }
  
  .hero__button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
  }
}
