.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #202020;
}

.profile-content {
  display: flex;
  flex-direction: column;
}

.profile-form {
  width: 410px;
  height: 482px;
  display: flex;
  flex-direction: column;
}

.profile-content__form {
  margin-top: 78px;
}

.profile-form__title {
  margin: 0;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.profile-form__fieldset {
  margin: 0;
  margin-top: 123px;
  padding: 0;
  border: none;
}

.profile-form__field {
  position: relative;
  display: block;
  height: 13px;

  transition: height 0.3s ease;
}

.profile-form__field:not(:last-of-type) {
  margin-bottom: 36px;
}

.profile-form__field:not(:last-of-type)::after {
  content: "";
  display: block;
  border-bottom: 1px solid #424242;
  height: 100%;
  padding-bottom: 17px;
}

.profile-form__field_mode_edit.profile-form__field::after {
  visibility: hidden;
}

/* Ниже для лейбла и инпута использую абсолютное позиционирование специально чтобы
сделать плавный transition при переходе в режим редактирования. С флексом плавности 
не получалось. */

.profile-form__label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;

  transition: all 0.3s ease;
}

.profile-form__input {
  width: 170px;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  background-color: transparent;

  color: #fff;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;

  transition: all 0.3s ease;
}

.profile-form__field_mode_edit {
  height: 48px;
}

.profile-form__field_mode_edit .profile-form__label {
  color: #8b8b8b;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.profile-form__field_mode_edit .profile-form__input {
  text-align: left;
  width: 100%;

  padding: 0 0 10px;
  border-bottom: solid 1px #424242;

  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.profile-form__input:focus {
  outline: none;
  border-color: #4285f4;
}

.profile-form__input_type_error {
  color: #ee3465;
}

.profile-form-error {
  color: #ee3465;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s ease, opacity 0.3s ease;
}

.profile-form-error_active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.profile-form__input-error {
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  width: 100%;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.profile-form__container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-form__container_hidden {
  display: none;
}

.profile-form__link-btn {
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;

  color: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  cursor: pointer;

  transition: all 0.3s ease;
}

.profile-form__link-btn:hover {
  opacity: 0.8;
}

.profile-form__link-btn:not(:first-of-type) {
  margin-top: 16px;
}

.profile-form__link-btn_color_red {
  color: #ee3465;
  font-weight: 500;
}

.profile-form__submit-error {
 
  align-self: center;

  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
}

.profile-form__submit-btn {
  display: block;
  align-self: stretch;
  margin-top: 20px;
  padding: 13px 30px 15px;
  background-color: #4285f4;
  border: none;
  border-radius: 3px;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  cursor: pointer;

  transition: all 0.3s ease;
}

.profile-form__submit-btn:hover {
  opacity: 0.8;
}

.profile-form__submit-btn:active {
  transform: scale(0.94);
}

.profile-form__submit-btn:disabled {
  background-color: transparent;
  border: 1px solid #fff;
  opacity: 0.2;
  pointer-events: none;
}

.profile-form__submit-btn_type_success {
  background-color: #49b888;
  pointer-events: none;
}


@media screen and (max-width: 768px) {
  .profile-form {
    height: 445px;
  }
  
  .profile-content__form {
    margin-top: 41px;
  }
  
  .profile-form__fieldset {
    margin-top: 96px;
  }
}


@media screen and (max-width: 480px) {
  .profile {
    justify-content: stretch;
    align-items: stretch;
  }
  
  .profile-content {
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    padding: 144px 30px 40px;
  }
  
  .profile-form {
    width: auto;
    height: auto;
    flex-grow: 1;
  }
  
  .profile-content__form {
    margin-top: 0;
  }
  
  .profile-form__fieldset {
    margin-top: 80px;
  }
  
  .profile-form__link-btn {
    font-size: 12px;
    line-height: 15px;
  }
  
  .profile-form__submit-btn {
    padding: 15px 30px;
    
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }
}