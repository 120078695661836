.techs {
  display: flex;
  flex-direction: column;
  padding: 100px 70px;
  background-color: #272727;
}

.techs__title {
  margin: 0;
  margin-top: 90px;
  color: #fff;
  text-align: center;
  font-size: 50px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
}

.techs__text {
  align-self: center;
  color: #fff;
  margin: 0;
  margin-top: 26px;
  max-width: 460px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
}

.techs__items {
  display: flex;
  padding: 0;
  margin: 0;
  margin-top: 90px;
  align-self: center;
  justify-content: center;
  list-style: none;
}

.techs__item {
  box-sizing: border-box;
  color: #fff;
  background-color: #303030;
  padding: 21px 14px 22px;
  width: 90px;
  border-radius: 10px;

  margin-right: 5px;
  margin-left: 5px;
  
  margin-top: 10px;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.56px;
}


@media screen and (max-width: 820px) {
  .techs__items {
    justify-content: center;
    flex-wrap: wrap;
    max-width: 500px;
  }
}


@media screen and (max-width: 768px) {
  .techs {
    padding: 90px 50px;
  }
  
  .techs__title {
    margin-top: 80px;
  }
  
  .techs__text {
    margin-top: 22px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
  }
  
  .techs__items {
    margin-top: 73px;
    max-width: unset;
  }
  
  .techs__item {
    padding: 21px 14px 21px;
    width: 84px;
  
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.48px;
  }
}

@media screen and (max-width: 759px) {
  .techs__items {
    max-width: 500px;
  }
}


@media screen and (max-width: 480px) {
  .techs {
    padding: 70px 18px;
  }
  
  .techs__title {
    margin-top: 60px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1.2px;
  }
  
  .techs__text {
    margin-top: 24px;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }
  
  .techs__items {
    margin-top: 40px;
  }
}

@media screen and (max-width: 320px) {
  .techs__items {
    justify-content: start;
    max-width: 188px;
  }
}