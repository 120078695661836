.page {
  background-color: #202020;
  color: #fff;
  font-family: 'Inter', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page__content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}
