.logo {
  line-height: 0;
  transition: opacity 0.3s ease;
}

.logo__image {
  width: 36px;
}

.logo:hover {
  opacity: 0.8;
}

.form-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #202020;
}

.form-content {
  display: flex;
  flex-direction: column;
  /* width: 396px; */
}

.form-content__logo {
  align-self: flex-start;
}

.form {
  width: 396px;
  height: 482px;
  display: flex;
  flex-direction: column;
}

.form-content__form {
  margin-top: 40px;
}

.form__title {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

.form__fieldset {
  margin: 0;
  margin-top: 40px;
  padding: 0;
  border: none;
}

.form__field {
  position: relative;
  display: block;
}

.form__field:not(:first-of-type) {
  margin-top: 24px;
}

.form__label {
  display: block;
  color: #8b8b8b;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.form__input {
  /* display: block; */
  width: 100%;
  margin-top: 5px;
  padding: 0 0 10px;
  border: none;
  border-bottom: solid 1px #424242;
  background-color: transparent;

  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.form__input:focus {
  outline: none;
  border-color: #4285f4;
}

.form__input_type_error {
  color: #ee3465;
}

.form-error {
  color: #ee3465;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s ease, opacity 0.3s ease;
}

.form-error_active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.form__input-error {
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  width: 100%;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.form__submit-error {
  margin-top: auto;
  align-self: center;

  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
}

.form__submit-btn {
  display: block;
  margin-top: 20px;
  padding: 13px 30px 15px;
  background-color: #4285f4;
  border: none;
  border-radius: 3px;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  cursor: pointer;

  transition: all 0.3s ease;
}

.form__submit-btn:hover {
  opacity: 0.8;
}

.form__submit-btn:active {
  transform: scale(0.94);
}

.form__submit-btn:disabled {
  background-color: transparent;
  border: 1px solid #fff;
  opacity: 0.2;
  pointer-events: none;
}

.form__link-container {
  align-self: center;
  margin-top: 16px;
}

.form__link-label {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.form__link {
  margin-left: 6px;
  text-decoration: none;
  color: #4285f4;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  transition: opacity 0.3s ease;
}

.form__link:hover {
  opacity: 0.8;
}


@media screen and (max-width: 480px) {

  .form-screen {
    justify-content: stretch;
    align-items: stretch;
  }
  
  .form-content {
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    
    padding: 56px 30px 30px;
  }
  
  .form-content__logo {
    align-self: center;
  }
  
  .form {
    width: auto;
    height: auto;
    
    flex-grow: 1;
  }
  
  .form-content__form {
    margin-top: 50px;
  }
  
  .form__title {
    align-self: center;
  }
  
  .form__fieldset {
    margin-top: 80px;
  }
  
  .form__submit-btn {
    padding: 15px 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
  }
  
  .form__link-container {
    margin-top: 14px;
  }
  
  .form__link-label {
    font-size: 12px;
    line-height: 15px;
  }
  
  .form__link {
    margin-left: 7px;
    font-size: 12px;
    line-height: 15px;
  }
}
