.logo {
  line-height: 0;
  transition: opacity 0.3s ease;
}

.logo__image {
  width: 36px;
}

.logo:hover {
  opacity: 0.8;
}

.header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 18px 70px 18px;
  z-index: 1;
}

.page__header {
  position: absolute;
  width: 100%;
}


@media screen and (max-width: 768px) {
  .header {
    padding: 18px 30px;
  }
}


@media screen and (max-width: 480px) {
  .header {
    padding: 18px 14px;
  }
}