.portfolio {
  padding: 0 70px 115px;
  background-color: #202020;
}

.portfolio__header {
  margin: 0;
  color: #8b8b8b;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.portfolio__items {
  padding: 0;
  margin: 0;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.portfolio__item {
  padding-bottom: 9px;
  margin-top: 20px;
  border-bottom: 1px solid #424242;
}

.portfolio__item:last-child{
  padding-bottom: 0;
  border-bottom: none;
}

.portfolio__link {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  text-decoration: none;

  transition: opacity 0.3s ease;
}

.portfolio__link:hover {
  opacity: 0.8;
}

.portfolio__name {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -1.2px;
}

.portfolio__arrow {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.2px;
}


@media screen and (max-width: 768px) {
  .portfolio {
    padding: 0 50px 80px;
  }
  
  .portfolio__header {
    color: #a0a0a0;
  }
  
  .portfolio__item {
    border-bottom: 1px solid #e6e6e6;
  }
  
  .portfolio__item:last-child{
    padding-bottom: 0;
    border-bottom: none;
  }
  
  .portfolio__link {
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    text-decoration: none;
  
    transition: opacity 0.3s ease;
  }
  
  .portfolio__link:hover {
    opacity: 0.8;
  }
  
  .portfolio__name {
    font-size: 28px;
    line-height: 50px;
    letter-spacing: -1.12px;
  }
  
  .portfolio__arrow {
    font-size: 28px;
    line-height: 60px;
    letter-spacing: -1.12px;
  }
}


@media screen and (max-width: 600px) {

  .portfolio__item {
    padding-bottom: 2px;
    margin-top: 12px;
  }

  .portfolio__name {
    font-size: 22px;
    line-height: 50px;
    letter-spacing: -1.12px;
  }
  
  .portfolio__arrow {
    font-size: 22px;
    line-height: 60px;
    letter-spacing: -1.12px;
  }
}


@media screen and (max-width: 480px) {
  .portfolio {
    padding: 0 14px 70px;
  }
  
  .portfolio__header {
    font-size: 14px;
    line-height: 20px;
  }
  
  .portfolio__items {
    margin-top: 20px;
  }
  
  .portfolio__item {
    padding-bottom: 19px;
    margin-top: 20px;
  }
  
  .portfolio__name {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
  
  .portfolio__arrow {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
}
