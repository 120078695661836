.error-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #202020;
}

.error-screen__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;

  height: 100%;
  max-height: 580px;
  min-height: 394px;
  flex-grow: 1;
}

.error-screen__status {
  margin: 0;
  margin-top: auto;
  color: #fff;
  font-size: 140px;
  font-weight: 400;
  line-height: 169px;
}

.error-screen__message {
  margin: 0;
  margin-top: 5px;
  max-width: 400px;

  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.error-screen__link {
  margin-top: 184px;

  text-decoration: none;
  color: #4285f4;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  transition: opacity 0.3s ease;
}

.error-screen__link:hover {
  opacity: 0.8;
}


@media screen and (max-width: 480px) {
  .error-screen {
    justify-content: stretch;
  }
  
  .error-screen__content {
    margin-bottom: 30px;
    margin-top: 45px;
    max-height:none;
  }
  
  .error-screen__status {
    margin-top: auto;
    font-size: 80px;
    line-height: 97px;
  }
  
  .error-screen__message {
    margin-top: 10px;
    max-width: 300px;
  
    font-size: 12px;
    line-height: 15px;
  }
  
  .error-screen__link {
    margin-top: auto;
    font-size: 12px;
    line-height: 15px;
  }
}
