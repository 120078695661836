.section-header {
  margin: 0;
  padding: 0;
  padding-bottom: 23px;

  color: #fff;
  border-bottom: 1px solid #dadada;
  
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.88px;
}

@media screen and (max-width: 480px) {
  .section-header {
    padding-bottom: 28px;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.72px;
  }
}