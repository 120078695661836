.footer {
  box-sizing: border-box;
  padding: 79px 70px 20px;
  background-color: #202020;
}

.footer__title {
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #424242;
  color: #8b8b8b;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.footer__bottom-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footer__year {
  margin: 0;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.footer__link-items {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__link-item {
  line-height: 0;
}

.footer__link-item:not(:last-child) {
  margin-right: 20px;
}

.footer__link {
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;

  transition: opacity 0.3s ease;
}

.footer__link:hover {
  opacity: 0.8;
}


@media screen and (max-width: 768px) {
  .footer {
    padding: 79px 30px 20px;
  }
}


@media screen and (max-width: 480px) {
  .footer {
    padding: 79px 10px 20px;
  }
  
  .footer__title {
    padding-bottom: 21px;
    font-size: 12px;
    line-height: 15px;
  }
  
  .footer__bottom-container {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  
  .footer__year {
    order: 2;
    margin-top: 30px;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 15px;
  }
  
  .footer__link-items {
    order: 1;
    flex-direction: column;
    align-items: center;
  }
  
  .footer__link-item {
    line-height: 0;
  }
  
  .footer__link-item:not(:last-child) {
    margin:0;
    margin-bottom: 12px;
  }
  
  .footer__link {
    font-size: 12px;
    line-height: 15px;
  }
}