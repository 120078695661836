.search {
  padding: 144px 70px 0px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;  
}

.search__form-container {
  padding-bottom: 69px;
  border-bottom: 1px solid #424242;

  z-index: 0;
}

.search__form {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 9px;
  position: relative;
  background-color: #2f2f2f;
}

.search__form:has(.search__request-input:focus)::after {
  content: ""; 
  position: absolute; 
  inset: 1px; 
  background: linear-gradient(to bottom right, 
      rgba(0, 94, 255, 0.4), rgba(239, 229, 180, 0.4)); 
  filter: blur(4px); 
  z-index: -1;
}

.search__form:has(.search__request-input:focus) {
  outline: 1px solid #424242bb;
  outline-offset: -1px;
}

.search__fieldset {
  border: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
}

.search__main-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 72px;
  border-radius: 9px 0px 0px 9px;

  border-right: 1px solid #424242;
  border-image: linear-gradient(to top, transparent 23%, #424242 23%, #424242 77%, transparent 77%) 1;
}

.search__request-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search__request-icon {
  width: 34px;
  margin-left: 19px;
}

.search__request-input {
  padding: 0;
  margin-left: 10px;
  flex: 1;
  width: 0;
  background-color: transparent;
  border: none;
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
}

.search__request-input:focus {
  outline: none;
}

.search__request-input::placeholder {
  color: #8b8b8b;
}

.search__input-error {
  position: absolute;
  left: 9px;
  bottom: -18px;
  width: 100%;
  color: #ee3465;

  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s ease, opacity 0.3s ease;
}

.search__input-error_active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.search__submit-btn {
  margin-left: 22px;
  margin-right: 29px; 
  padding: 8px 12px;
  border: none;
  border-radius: 48px;
  background-color: #4285f4;
  cursor: pointer;

  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px; /* 163.636% */

  transition: opacity 0.3s ease, transform 0.1s ease;
}

.search__submit-btn:hover {
  opacity: 0.8;
}

.search__submit-btn:active {
  transform: scale(0.92);
}

.search__checkbox-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 72px;
  border-radius: 0px 9px 9px 0px;
  padding: 0 30px;
}


@media screen and (max-width: 768px) {
  .search {
    padding: 154px 30px 0px; 
  }
  
  .search__form-container {
    padding-bottom: 109px;
  }

  .search__main-container {
    border-image: linear-gradient(to top, transparent 23%, #505050 23%, #505050 77%, transparent 77%) 1;
  }
  
  .search__request-label {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .search__submit-btn {
    margin-right: 19px; 
  }

  .search__checkbox-container {
    padding: 0 20px;
  }
}


@media screen and (max-width: 600px) {
  .search__form-container {
    padding-bottom: 44px;
  }

  .search__form {
    background-color: transparent;
}
  
  .search__fieldset {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search__main-container {
    flex-grow: 0;
    border-radius: 9px;
    border: none;

    background-color: #2f2f2f;
    position: relative;
  }

  .search__form:has(.search__request-input:focus)::after {
    content: none; 
  }

  .search__form:has(.search__request-input:focus) {
    outline: none;
  }


  .search__main-container:has(.search__request-input:focus)::after {
    content: ""; 
    position: absolute; 
    inset: 1px; 
    background: linear-gradient(to bottom right, 
        rgba(0, 94, 255, 0.4), rgba(239, 229, 180, 0.4)); 
    filter: blur(4px); 
    z-index: -1;
  }

  .search__main-container:has(.search__request-input:focus) {
    outline: 1px solid #424242bb;
    outline-offset: -1px;
  }
  
  .search__submit-btn {
    margin-left: 22px;
    margin-right: 30px; 
  }
  
  .search__checkbox-container {
    align-self: center;
    margin-top: 45px;
    height:auto;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
  }
}


@media screen and (max-width: 480px) {
  .search {
    padding: 154px 10px 0px;
  }

  .search__request-icon {
    display: none;
  }
  
  .search__request-input {
    margin-left: 19px;
    font-size: 14px;
    line-height: 20px;
  }
  
  .search__submit-btn {
    margin-left: 22px;
    margin-right: 19px; 
  }
}
