.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  list-style: none;
  padding: 6px;
  margin: 0;
  /* overflow: hidden; */

}

.card__image {
  height: 151px;
  object-position: center;
  object-fit: cover;
  cursor: pointer;

  width: 100%;
}

.card__name-container {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  margin-top: 10px;

  position: relative;
}

.card__name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 32px;
  margin:0;
  margin-top: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px; 
}

.card__btn {
  margin-left: 20px;
  width: 24px;
  height: 24px;
  padding: 0px;
  border: none;
  border-radius: 50%;
  background-color: #313131;
  transition: all 0.3s ease, transform 0.1s ease;
  cursor: pointer;
  flex-shrink: 0;
  line-height: 0;
}

.card__btn:hover {
  opacity: 0.8;
}

.card__btn:active {
  transform: scale(0.92);
}

.card__like-icn {
  color: white;
  transition: color 0.3s ease;
}

.card__btn_clicked .card__like-icn  {
  color: #ee3465;
}

.card__btn_type_delete {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover .card__btn_type_delete {
  position: static;
  visibility: visible;
  opacity: 1;
}

.card:hover .card__btn_type_delete:hover {
  opacity: 0.8;
}

.card__delete-icn {
  position: relative;
  vertical-align: middle;
  text-align: center;
}

.card__time {
  margin: 0;
  margin-top: 13px;
  padding-top: 6px;
  border-top: 1px solid #424242;
  color: #8b8b8b;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
}


@media screen and (max-width: 768px) {
  .card__image {
    height: 190px;
  }
}


@media screen and (max-width: 480px) {
  .card__image {
    height: auto;
    max-height: 220px;
    aspect-ratio: 1.7857;
  }
  
  .card__name {
    margin-top: 5px;
    font-size: 12px;
    line-height: 15px; 
  }
  
  .card__time {
    font-size: 10px;
    line-height: 12px;
  }
}
